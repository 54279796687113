import Layout from 'components/layout';
import { Carousel, Modal, Row, Col } from 'antd';
import style from 'styles/home/index.module.scss';
import Image from 'components/image';
import CdnImage from 'components/image/cdnImage';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Button from 'components/button';
import ContactBtnGroup from 'components/contact';
import { actionServer } from 'action/index';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import Loading from 'components/loading';
import UseResize from 'utils/hooks/useResize';
import CustomLink from 'components/link';
import { bannerList, coreIntroductions, IBannerList, ICoreIntroductions, ISolution, mapNumbers, mp4Url, muteMp4Url, mutePoster, prefix, solutions } from 'constants/front_page';
import Transition from 'components/transition';
import { JumpIntro } from 'components/jumpIntro';

export default function IndexView(prop: any) {
  const { news } = prop;
  const [videoVisible, setVideoVisible] = useState(false);
  // 璁板綍琛屼笟搴旂敤瑙ｅ喅鏂规鎵撳紑item涓嬫爣
  const [solActiveIdx, setSolActiveIdx] = useState(0);
  const [animationComplete, setAnimationComplete] = useState(false);
  const isFirst = useRef(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  // 杞挱鍥捐嚜鍔ㄥ垏鎹n  const carouselRef = useRef<any>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // 鏄惁绉诲姩绔痋n  const isMobile = UseResize().width < 768;
  const urlPrefix = `${prefix}/${isMobile ? 'M' : 'PC'}/`;

  // 鎾斁瑙嗛
  const playVideo = (bool: boolean) => {
    setVideoVisible(bool);
    if (bool) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  };

  // 琛屼笟瑙ｅ喅鏂规妯″潡杩涘叆瑙嗗彛鍥炶皟
  const handleAnimationComplete = () => {
    setTimeout(() => {
      setAnimationComplete(true);
    }, 1500);
  };

  // 榧犳爣绉诲叆鏌愪釜solution鍏冪礌
  const handleMouseEnterSolution = (index: number) => {
    setAnimationComplete(false);
    setSolActiveIdx(index);
    isFirst.current = index === 0;
  };

  // banner鑷姩鎾斁
  const startAutoPlay = () => {
    const id = setInterval(() => {
      if (carouselRef.current) {
        setCurrentSlide(prev => (prev + 1) % bannerList.length);
        carouselRef.current.next();
      }
    }, 4000);
    setIntervalId(id);
  };

  const stopAutoPlay = () => {
    // 鍋滄鑷姩鎾斁
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => {
    // 缁勪欢鎸傝浇鏃跺紑濮嬭嚜鍔ㄦ挱鏀綷n    startAutoPlay();
    return stopAutoPlay;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      // 鏍规嵁 currentSlide 鏇存柊杞挱
      carouselRef.current.goTo(currentSlide);
    }
  }, [currentSlide]);

  const handleChange = (current: number) => {
    setCurrentSlide(current);
    stopAutoPlay();
    startAutoPlay();
  };

  const router = useRouter();
  if (router.isFallback) {
    return <Loading />;
  }

  return (
    <Layout>
      {/* banner骞荤伅鐗囨ā鍧 */}
      <section className={style.advertise}>
        <Carousel
          dots={{
            className: style.dots,
          }}
          ref={carouselRef}
          autoplay={false}
          afterChange={handleChange}
        >
          {bannerList.map((item: IBannerList) => {
            const {
              key,
              content: { title, desc, mDesc, intro },
              btnJumpLink,
              isTryBtn,
            } = item;
            return (
              <div key={key}>
                <div className={cn(style.advertiseItem, style[key])}>
                  <div className={cn(style.content, style[key])}>
                    <p className={style.title}>{title}</p>
                    <p className={style.desc}>{isMobile ? mDesc || desc : desc}</p>
                    {intro && <p className={style.intro}>{intro}</p>}
                  </div>
                  <div className={style.btnWrapper}>
                    {isTryBtn && (
                      <CustomLink isNextLink={false} href={'/try'}>
                        <Button category="banner" type="primary" color="default" className={style.btnTry}>
                          绔嬪嵆璇曠敤
                        </Button>
                      </CustomLink>
                    )}
                    {btnJumpLink && (
                      <CustomLink isNextLink={false} href={btnJumpLink}>
                        <Button category="banner" type="primary">
                          鏌ョ湅璇︽儏
                        </Button>
                      </CustomLink>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </section>
      {/* 鐐瑰嚮瑙嗛锛屼簡瑙ｅ皬楸兼槗杩 */}
      <section className={style.videoWrapper}>
        <video
          className={style['for-pc']}
          autoPlay
          muted
          loop
          src={muteMp4Url}
          poster={mutePoster}
          webkit-playsinline="true"
          playsInline={true}
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-ignore-metadata="true"
          preload="auto"
        >
          鎮ㄧ殑娴忚鍣ㄤ笉鏀寔video
        </video>
        <div className={style.subImg}>
          <p className={style.title}>鐐瑰嚮瑙嗛锛屼簡瑙ｅ皬楸兼槗杩濣/p>
          <Image className={style.playBtn} src="/v2/images/home/pc_play.png" alt="playBtn" onClick={() => playVideo(true)} />
        </div>
        <div className={style.shadow} />
      </section>
      {/* 鏍稿績浠嬬粛妯″潡 */}
      <section className={style.coreIntroductions}>
        {coreIntroductions.map((item: ICoreIntroductions) => {
          return (
            <CustomLink isNextLink={false} href={item.descJumpUrl} key={item.key}>
              <Transition delay={0.5}>
                <div className={cn(style.coreIntroduction, style[item.key])} style={{ backgroundImage: `url(${urlPrefix}${item.key}.jpg)` }}>
                  <Transition delay={0.8}>
                    <h1 className={style.title}>{item.title}</h1>
                  </Transition>
                  <Transition delay={1.1}>
                    <CustomLink className={style.desc} isNextLink={false} href={item.descJumpUrl}>
                      <JumpIntro desc={item.desc} />
                    </CustomLink>
                  </Transition>
                </div>
              </Transition>
            </CustomLink>
          );
        })}
      </section>

      {/* 鍦板浘妯″潡 */}
      <section className={style.mapWrapper}>
        <video
          className={style['for-pc']}
          autoPlay
          muted
          loop
          src={`${prefix}/PC/map_video.mp4`}
          poster={`${prefix}/PC/map_poster.jpg`}
          webkit-playsinline="true"
          playsInline={true}
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-ignore-metadata="true"
          preload="auto"
        >
          鎮ㄧ殑娴忚鍣ㄤ笉鏀寔video
        </video>
        <div className={cn(style['for-mobile'], style.mapContent)}>
          <CdnImage className={style.mapImg} src="https://sdk-source.xylink.com/website/assets/home/M/test_animate.gif" alt="map" />
          <div className={style.mapText}>
            <Transition delay={0.5}>
              <p dangerouslySetInnerHTML={{ __html: '姣忎竴澶‐
鍦ㄥ叏鐞冭繎鐧句釜鍥藉锛岃繎鍗冨骇鍩庡競\n瓒呰繃30涓囨鐨勮繙绋嬭棰戣繛绾縗
閫氳繃灏忛奔鏄撹繛寮€鍚?.replace(/\n/g, '<br/>') }}></p>
            </Transition>
          </div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.numberContent}>
            {mapNumbers.map(item => (
              <Transition delay={0.8} key={item.key}>
                <div className={cn(style.numberItem, style[item.key])}>
                  <span className={style.number}>{item.number}</span>
                  <span className={style.text}>{item.text}</span>
                </div>
              </Transition>
            ))}
          </div>
          <div className={cn(style['for-pc'], style.textContent)}>
            <Transition delay={0.8}>
              <p dangerouslySetInnerHTML={{ __html: '姣忎竴澶╋紝鍦ㄥ叏鐞冭繎鐧句釜鍥藉锛岃繎鍗冨骇鍩庡競锛孿
瓒呰繃30涓囨鐨勮繙绋嬭棰戣繛绾匡紝閫氳繃灏忛奔鏄撹繛寮€鍚€?.replace(/\n/g, '<br/>') }}></p>
            </Transition>
          </div>
        </div>
      </section>
      {/* 琛屼笟瑙ｅ喅鏂规 */}
      <section className={style.solWrapper}>
        <Transition delay={0.5}>
          <h1 className={style.bigTitle}>琛屼笟瑙ｅ喅鏂规</h1>
        </Transition>
        <Transition delay={0.8} onAnimationComplete={handleAnimationComplete}>
          <div className={style.contentWrapper}>
            {solutions.map((item: ISolution, index: number) => {
              const { key, title, desc } = item;
              // 绗竴涓厓绱犲湪瑙嗗彛鍐呮椂婵€娲?骞朵笖姝ゆ椂榧犳爣娌＄粡杩囦换浣昳tem,绗竴涓厓绱犳坊鍔犵澶村姩鐢籠n              const isInitial = animationComplete && solActiveIdx === 0 && index === 0;
              const isActive = solActiveIdx === index;
              const className = `${style.solution} ${((isActive && index !== 0) || (index === 0 && isFirst.current)) && !isMobile && style.active} ${isInitial && !isMobile && style.initialActive}`;

              return (
                <div
                  key={key}
                  className={className}
                  onMouseEnter={() => handleMouseEnterSolution(index)}
                  style={
                    {
                      '--close-image-url': `url(${prefix}/${(isMobile && 'M/') || ''}solutions/close/${key}.jpg)`,
                      '--open-image-url': `url(${prefix}/solutions/open/${key}.jpg)`,
                    } as React.CSSProperties
                  }
                >
                  <CustomLink isNextLink={false} href={item.link} className={style.itemContentWrapper}>
                    <div className={style.content}>
                      <div className={style.intro}>
                        <p className={style.title}>{title}</p>
                        <p className={style.desc}>{desc}</p>
                        <CustomLink isNextLink={false} href={item.link} className={style.jumpIntro}>
                          <JumpIntro />
                        </CustomLink>
                      </div>
                      <div className={style.arrow} style={{ backgroundImage: `url(${prefix}/solutions/open/${key}_arrow.png)` }}></div>
                    </div>
                  </CustomLink>
                </div>
              );
            })}
          </div>
        </Transition>
      </section>
      {/* 鏂伴椈妯″潡 */}
      <section className={style.newsWrapper}>
        <header>
          <Transition delay={0.5}>
            <div className={style.title}>鍏徃鏂伴椈</div>
          </Transition>

          <Transition delay={0.8}>
            <CustomLink isNextLink={false} href={'/news'}>
              <div className={style.subTitle}>
                <span>鏇村鏂伴椈</span>&nbsp;>
              </div>
            </CustomLink>
          </Transition>
        </header>
        <Transition delay={1}>
          <div className={style.inner}>
            <Row gutter={[{ xs: 0, sm: 0, md: 20 }, 0]}>
              {news.filter(Boolean).map((item: any) => {
                return (
                  <Col key={item.id} span={24} md={8} lg={8}>
                    <CustomLink isNextLink={false} href={`/news/${item.id}`}>
                      <div className={style.news}>
                        <img className={style.poster} src={item.newsCover} alt={item.newsTitle} />
                        <div className={style.detail}>
                          <p className={style.title}>{item.newsTitle}</p>
                          <CustomLink isNextLink={false} href={`/news/${item.id}`} className={style.jumpDetails}>
                            <JumpIntro />
                          </CustomLink>
                        </div>
                      </div>
                    </CustomLink>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Transition>
      </section>
      {/* 鍚堜綔浼欎即 */}
      <section className={style.partnerWrapper}>
        <div className={style.title}>鍜屼粬浠竴璧烽€夋嫨灏忛奔鏄撹繛</div>
        <CdnImage className={style.partnersImg} src={`${urlPrefix}partners.jpg`} alt="partners" />
      </section>
      {/* 鑱旂郴鎴戜滑 */}
      <ContactBtnGroup />
      <Modal
        title=" "
        closeIcon={<Image className={style.closeIcon} src="/v2/images/home/live-closer.png" alt="font" />}
        visible={videoVisible}
        centered={true}
        footer={null}
        className={style.videoModal}
        onCancel={() => playVideo(false)}
      >
        <video ref={videoRef} className={style.videoPlayer} controls autoPlay src={mp4Url}>
          鎮ㄧ殑娴忚鍣ㄤ笉鏀寔video
        </video>
      </Modal>
    </Layout>
  );
}
export const getStaticProps: GetStaticProps = async context => {
  const res = await actionServer.getHomeNews();
  return {
    props: {
      news: res || [],
    },
    revalidate: 30,
  };
};
