export const prefix = 'https://sdk-source.xylink.com/website/assets/home';
export const mp4Url = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/b456ec7876112be44741308cac4a8f03950efeda22d12830b7cd4b049c6bf21d.mp4';
export const muteMp4Url = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/3fac15ca1bdf4d4f3d63d796456348e633a906f11ed6646cbe2bd0398ca1b9d2.mp4';
export const mutePoster = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/f6934c353137328c363c96e836af8f08ba2c7a608446df33a20fd9f7d40461ed.jpg';

/**
 * 棣栭〉banner
 *
 * @param { string } key - 鏍囪瘑
 * @param { string } bgImg - PC鑳屾櫙鍥綷n * @param { string } mBgImg - 绉诲姩绔儗鏅浘
 * @param { string } btnJumpLink - 鐐瑰嚮鎸夐挳璺宠浆鐨刄RL
 * @param { boolean } isTryBtn - 鏄惁鏄剧ず绔嬪嵆璇曠敤鎸夐挳
 */
export interface IBannerList {
  key: string;
  content: {
    title: string;
    desc: string;
    mDesc?: string;
    intro?: string;
  };
  btnJumpLink?: string;
  isTryBtn?: boolean;
}

/**
 * 棣栭〉banner淇℃伅
 */
export const bannerList: IBannerList[] = [
  {
    key: 'newProduct',
    btnJumpLink: '/new_product_2024',
    content: {
      title: '鈥淪VC+AVC鈥濆弻寮曟搸',
      desc: '寮曢鏂颁竴浠ｈ棰戜細璁?,
    },
  },
  {
    key: 'advantage',
    btnJumpLink: '/advantage',
    content: {
      title: '灏忛奔鏄撹繛銆岀‖銆嶅疄鍔?,
      desc: '楂樺畨鍏犅仿犺秴铻嶅悎聽路聽鍏ㄨ繛鎺?,
      mDesc: '楂樺畨鍏疯秴铻嶅悎路鍏ㄨ繛鎺?,
      intro: '',
    },
  },
  {
    key: 'try',
    btnJumpLink: '/news/faa83a',
    // isTryBtn: true,
    content: {
      title: '涓浗绉佹湁浜戜細璁?,
      desc: '甯傚満鍗犳湁鐜囩涓€',
      intro: '鏁版嵁婧愪簬IDC鎶ュ憡銆婁腑鍥戒簯浼氳甯傚満浠介銆?,
    },
  },
  {
    key: 'whitePaper',
    btnJumpLink: '/news/946',
    content: {
      title: '閲嶇鍙戝竷瑙嗛浼氳琛屼笟绯诲垪鐧界毊涔?,
      desc: '浜戣棰戝叧閿妧鏈強鏍囧噯鍖栫櫧鐨功/淇濆瘑瑙嗛浼氳绯荤粺閫氳鍗忚',
      intro: '鏁板瓧鏀垮簻/鏁板瓧澶紒/鏁板瓧閾惰铻嶅悎瑙嗛绯荤粺寤鸿鐧界毊涔?,
    },
  },
];

/**
 * @param { string } title - 鏍囬
 * @param { string } desc - 鎻忚堪
 * @param { string } bgImg - PC鑳屾櫙鍥綷n * @param { string } mBgImg - 绉诲姩绔儗鏅浘
 * @param { string } descJumpUrl - 鐐瑰嚮鎻忚堪璺宠浆鐨刄RL
 * @param { string } key - 妯″潡鍚嶇О鏍囪瘑
 */
export interface ICoreIntroductions {
  title: string;
  desc: string;
  descJumpUrl: string;
  key: string;
}

/**
 * 鏍稿績浠嬬粛妯″潡淇℃伅
 */
export const coreIntroductions: ICoreIntroductions[] = [
  { title: '鏃犳墍涓嶈瀺锛屻€屼細銆嶆棤杈圭晫', desc: '浜嗚В瓒呰瀺鍚堣棰戝钩鍙?, descJumpUrl: '/platform', key: 'platform' },
  { title: '鍝佽川浼氳锛屾矇娴镐綋楠?, desc: '浜嗚В鍏ㄨ繛鎺ヨ棰戠粓绔?, descJumpUrl: '/hardware', key: 'hardware' },
  { title: '涓氬姟闆嗘垚锛屽ぇ鏈夊彲涓?, desc: '浜嗚В娉涗笟鍔″紑鍙戝簳搴?, descJumpUrl: '/sdk_api', key: 'sdk_api' },
];

/**
 * @param { string } title - 鏍囬
 * @param { string } desc - 鎻忚堪
 * @param { string } smallImg - 鏈睍寮€鍓嶈儗鏅浘
 * @param { string } openImg - 灞曞紑鍚庡ぇ鑳屾櫙鍥綷n * @param { string } arrowImg - 鑳屾櫙绠ご
 * @param { string } link - 璺宠浆閾炬帴
 * @param { string } key - 妯″潡鍚嶇О鏍囪瘑
 */
export interface ISolution {
  title: string;
  desc: string;
  link: string;
  key: string;
}

/**
 * 琛屼笟瑙ｅ喅鏂规妯″潡淇℃伅
 */
export const solutions: ISolution[] = [
  {
    title: '鏀垮姟',
    desc: '璧嬭兘鏁板瓧鏀垮簻 璁╂斂鍔℃湇鍔￠浂璺濈',
    link: '/gov-solution',
    key: 'government',
  },
  {
    title: '鏀挎硶',
    desc: '鍔╁姏鏅烘収鏀挎硶 鍔犻€熸硶娌荤幇浠ｅ寲寤鸿',
    link: '/court-solution',
    key: 'court',
  },
  {
    title: '浼佷笟闆嗗洟',
    desc: '鏅鸿兘浜戜細璁 楂樻晥浜戝崗鍚?,
    link: '/group-solution',
    key: 'enterprise',
  },
  {
    title: '搴旀€?,
    desc: '楂樻晥鎻愬崌搴旀€ユ晥鐜 鏅烘収鎶よ埅绀句細瀹夊叏',
    link: '/er-solution',
    key: 'emergency',
  },
  {
    title: '姘村埄',
    desc: '璧嬭兘鏁板瓧瀛敓娴佸煙 鍒涙柊鎺ㄨ繘鏅烘収姘村埄',
    link: '/watercon-solution',
    key: 'waterConservancy',
  },
  {
    title: '閲戣瀺',
    desc: '浠ュ垱鏂扮鎶€ 鍔╂帹閲戣瀺琛屼笟鏁板瓧鍖栬浆鍨?,
    link: '/finance-solution',
    key: 'finance',
  },
];

/**
 * 鍦板浘妯″潡鐩稿叧瀹㈡埛鏁板瓧淇℃伅
 *
 * @param { string } number - 鏁板瓧
 * @param { string } text - 鏂囨
 * @param { string } key - 鏍囪瘑
 */
export const mapNumbers: { number: string; text: string; key: string }[] = [
  { number: '10+', text: '鐪佺骇鏁板瓧鏀垮簻瑙嗛骞冲彴', key: 'governments' },
  { number: '60+', text: '閮ㄥ鍜屽ぎ浼佸鎴?, key: 'enterprises' },
  { number: '500+', text: '鐪佸競/鍘呭眬瀹㈡埛', key: 'provincial_customers' },
  { number: '15+', text: '閲戣瀺鐩戠鏈烘瀯鍜屽ぇ鍨嬮摱琛?, key: 'finance_banks' },
  { number: '17涓?', text: '涓ぇ鍨嬪鎴?, key: 'clients' },
  { number: '8涓?', text: '灏忔椂绋冲畾杩愯', key: 'hours' },
];
